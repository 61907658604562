import React from 'react';
import styled from 'styled-components'

import { theme } from '../styles';
import { SectionContainer, ContentWrapper } from '../components/Containers'

import { ReactComponent as Icon } from '../assets/icons/about-us.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 0 30px;
  }
`

const StyledTitle = styled.p`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeights.bold};
`

const StyledParagraph = styled.p`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.normal};
`

export const AboutUsSection: React.FC = ({
}) => (    
  <SectionContainer bgColor={theme.colors.lightBlue}>
      <ContentWrapper>
          <Wrapper>
            <Icon display={'block'} />
            <div style={{ gridColumn: '5 / span 7' }} >
              <StyledTitle>What we do.</StyledTitle>
              <StyledParagraph>
                We create drop-in solutions to empower businesses to take control of their online platforms.
                Whether you are an enterprise level business, or if you are just starting out, we can help you take control of your online store!
              </StyledParagraph>
            </div>
          </Wrapper>
      </ContentWrapper>
  </SectionContainer>
);