import React from 'react';

import { theme } from '../styles';
import { SectionContainer, ContentWrapper } from '../components/Containers'
import { Hero } from '../components'

export const HeroSection: React.FC = ({
}) => (    
  <SectionContainer bgColor={theme.colors.purple} style={{height: '100%'}}>
      <ContentWrapper>
        <Hero>
          Empowering ecommerce solutions
        </Hero>
      </ContentWrapper>
  </SectionContainer>
);