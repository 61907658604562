import React from 'react'
import styled from 'styled-components'

import { theme } from '../styles'
import { SectionContainer, ContentWrapper } from '../components/Containers'

import easymailerDashboard from '../assets/easymailer-dashboard.png'

const Heading1 = styled.h1`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeights.bold};
  margin: 0 0 39px 0;
  line-height: 55px;

  span{
    font-size: ${theme.fontSize.small};
    vertical-align: middle;
  }
`
const Heading2 = styled.h1`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeights.regular};
  margin: 8px 0 5px 0;
  line-height: 39px;
`

const Img = styled.img`
  width: 100%;
`

export const ProductsSection: React.FC = ({
}) => (    
  <SectionContainer bgColor={theme.colors.white}>
      <ContentWrapper>
        <div>
          <Heading2>Software</Heading2>
          <Heading1>Easymailer <span>(Coming soon!)</span></Heading1>
        </div>
        <Img src={easymailerDashboard} alt="EasyMailer Dashboard"/>
      </ContentWrapper>
  </SectionContainer>
);