import React, {
  useState,
} from 'react'
import styled from 'styled-components'

import { theme } from '../styles';
import { SectionContainer, ContentWrapper } from '../components/Containers'
import { Form } from '../components'
import { 
  TextInput, 
  TextArea, 
  Select,
  Checkbox,
  ButtonPrimary,
  ButtonSecondary
} from '../components';

import { ReactComponent as Icon } from '../assets/icons/contact-us.svg'

const Container = styled.div`
  display: flex;
  @media screen and (max-width: 1150px) {
    justify-content: center;
  }
`

const IconWrapper = styled.div`
  display: none;
  position: relative;
  left: -75px;
  margin-bottom: 88px;
  @media screen and (min-width: 1150px) {
    display: block;
  }
`

const TitleContainer = styled.div`
  @media screen and (max-width: 1150px) {
    margin-bottom: 60px;
  }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Heading1 = styled.h1`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeights.bold};
  margin: 0 0 39px 0;
  line-height: 55px;
`
const Heading2 = styled.h1`
  color: ${theme.colors.purple};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeights.regular};
  margin: 8px 0 5px 0;
  line-height: 39px;
`

const TextSpan = styled.span`
  @media screen and (max-width: 768px) {
    position: relative;
    font-size: 16px;
    display: inline-block;
    max-width: 220px;
  }
`;

export const ContactUsSection: React.FC = ({
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  return (    
    <SectionContainer bgColor={theme.colors.lightBlue}>
        <ContentWrapper>
          <Container>
            <IconWrapper>
              <Icon />
            </IconWrapper>

            <FormContainer>
              <TitleContainer>
                <Heading2>We are here for you.</Heading2>
                <Heading1>Send us a message.</Heading1>
                <ButtonSecondary onClick={() => window.open('mailto:info@atiksolutions.co.uk')}>info@atiksolutions.co.uk</ButtonSecondary>
              </TitleContainer>

              <Form 
                title="Contact form" 
                name="contact" 
                method="post" 
                data-netlify="true" 
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <TextInput 
                  name="name"
                  label="Your name"
                  type="text"
                  style={{ gridColumn: 'span 1' }} 
                />
                <TextInput 
                  name="email"
                  label="Email" 
                  type="email"
                  style={{ gridColumn: 'span 1' }} 
                  required={true}
                />
                <Select 
                  name="topic"
                  label="Select a topic"
                  style={{ gridColumn: 'span 2' }} 
                >
                  <option value="support">I need help with one of your products</option>
                  <option value="technical-issue">I would like to report a bug</option>
                  <option value="information">I would like details about your products/services</option>
                  <option value="other">Something else</option>
                </Select>
                <TextArea 
                  name="message"
                  label="Your message"
                  style={{ gridColumn: 'span 2' }} 
                />
                <Checkbox 
                  name="agree_terms" 
                  style={{ gridColumn: 'span 2' }}
                  onChange={(e) => setCanSubmit(e.target.checked)}  
                >
                  <TextSpan>
                    I agree for the submitted data to be used and saved.
                  </TextSpan>
                </Checkbox>
                <ButtonPrimary 
                  onClick={() => console.log()} 
                  style={{width: '240px'}}
                  disabled={!canSubmit}
                >
                  Submit
                </ButtonPrimary>
              </Form>
            </FormContainer>
          </Container>
        </ContentWrapper>
    </SectionContainer>
  );
}
