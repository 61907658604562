import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Image from './image';
import { 
  HeroSection,
  ContactUsSection,
  AboutUsSection,
  ProductsSection
} from '../sections'


const IndexPage = () => (
  <Layout >
    <HeroSection />
    <AboutUsSection />
    <ProductsSection />
    <ContactUsSection />
  </Layout>
);

export default IndexPage;
